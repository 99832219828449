// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import 'firebase/compat/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC5t6Z-6OuRSB7K0-RXinJEsR8TYUGWYnc",
  authDomain: "resume-83978.firebaseapp.com",
  projectId: "resume-83978",
  storageBucket: "resume-83978.appspot.com",
  messagingSenderId: "195188576654",
  appId: "1:195188576654:web:21b882c0410c49e2ce94df",
  measurementId: "G-MJL0JDD51C"
};
firebase.initializeApp((firebaseConfig))
// Initialize Firebase
const functions = firebase.functions();
export default functions;