import React from 'react';
import SkillProgress from '../components/form/SkillProgress';
import { clounds, databases, frameworks, IDEs, programmingLanguages, unitTest } from '../data/Data';
import ExperienceBanner from '../components/experience-banner/ExperienceBanner';

const Skills = () => {
  return (
    <div className='skills'>
      <ExperienceBanner pageName='Skills' />
      <div className='row'>
        <div className='col'>
          {/**Programming languages */}
          <h3 className='title'>Programming languages</h3>
          <div className='skill-block'>
            {
              programmingLanguages.map((PL, index) => {
                return(
                  <SkillProgress
                    language={PL}
                    key={index}
                  />
                )
              })
            }
          </div>
          {/**Frameworks and libraries */}
          <h3 className='title'>Frameworks & Libraries</h3>
          <div className='skill-block'>
            {
              frameworks.map((framework, index) => {
                return(
                  <SkillProgress
                    language={framework}
                    key={index}
                  />
                )
              })
            }
          </div>
          {/**Cloud */}
          <h3 className='title'>Cloud & Serverless</h3>
          <div className='skill-block'>
            {
              clounds.map((cloud, index) => {
                return(
                  <SkillProgress
                    language={cloud}
                    key={index}
                  />
                )
              })
            }
          </div>
        </div>
        <div className='col'>
          {/**Database */}
          <h3 className='title'>Databases</h3>
            <div className='skill-block'>
              {
                databases.map((db, index) => {
                  return(
                    <SkillProgress
                      language={db}
                      key={index}
                    />
                  )
                })
              }
            </div>
            {/**IDE and Tools */}
            <h3 className='title'>IDE & Tools</h3>
            <div className='skill-block'>
              {
                IDEs.map((ide, index) => {
                  return(
                    <SkillProgress
                      language={ide}
                      key={index}
                    />
                  )
                })
              }
            </div>
            {/**IDE and Tools */}
            <h3 className='title'>Unit & Integration testing</h3>
            <div className='skill-block'>
              {
                unitTest.map((framework, index) => {
                  return(
                    <SkillProgress
                      language={framework}
                      key={index}
                    />
                  )
                })
              }
            </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;