import React from 'react';

interface Props {
  pageName: string
}

const ExperienceBanner: React.FC<Props> = ({ pageName }) => {
  return (
    <div className='page-header'>
      <h2>{pageName}</h2>
      <p>7 Years of Experience</p>
    </div>
  )
};

export default ExperienceBanner;