import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { BsLinkedin } from 'react-icons/bs';
import { FaBars } from 'react-icons/fa';

const Sidebar = () => {
  const [mobileView, setMobileView] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const updateViewState = () => {
    if (!mobileView && document.documentElement.clientWidth <= 990) {
      setMobileView(true);
    } else if (document.documentElement.clientWidth >= 991) {
      setMobileView(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", updateViewState);
    return () => {
      window.removeEventListener("resize", updateViewState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className={`mobile-header`}>
        <div className='content'>
          <div className='avatar'>
            <div className='profile-image' />
            <div className='name'>
              <Link to='/'>Ridouan Lamghari</Link>
            </div>
          </div>
          <button
            onClick={() => toggleSidebar()}
          >
            <FaBars size={25} />
          </button>
        </div>
      </div>
      <div className={`sidebar no-scrollbar ${showSidebar ? 'show' : 'hide'} ${mobileView ? 'mobile-view' : ''}`} id='sidebar'>
        <div className='sidebar-content'>
          <div className='avatar' />
          <div className='name'>
            <Link to='/'>Ridouan Lamghari</Link>
          </div>
          <div>
            <NavLink onClick={() => setShowSidebar(false)} to='/aboutme' className={({ isActive }: any) => `nav-item ${isActive ? 'nav-item-active': ''}`}>About me</NavLink>
            <NavLink onClick={() => setShowSidebar(false)} to='/experiences' className={({ isActive }: any) => `nav-item ${isActive ? 'nav-item-active' : ''}`}>Experiences</NavLink>
            <NavLink onClick={() => setShowSidebar(false)} to='/skills' className={({ isActive }: any) => `nav-item ${isActive ? 'nav-item-active' : ''}`}>Skills</NavLink>
            <NavLink onClick={() => setShowSidebar(false)} to='/contact' className={({ isActive }: any) => `nav-item ${isActive ? 'nav-item-active' : ''}`}>Contact</NavLink>
          </div>
          <div className='social-media'>
            <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/in/ridouan-lamghari👽-3979139b/'><BsLinkedin size={25} /></a>
            {/* <span><BsWhatsapp size={25} color={themeBlue} /></span> */}
          </div>
          <div className='copyright'>Copyright {new Date().getFullYear()}, All rights reserved.</div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;