import React from 'react';
import Sidebar from '../containers/navs/Sidebar';

const AppLayout: React.FC = ({
  children
}) => {
  return (
    <div className='container'>
      <div className='page'>
        <Sidebar />
        <main>
          <div>{children}</div>
        </main>
      </div>
    </div>
  );
};

export default AppLayout;