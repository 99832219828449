
export const aboutMe = {
  textInfo: [
    'I have been working in production enterprise projects and as a freelancer more than 7 years.',
    'During this period I have used many different technologies like ReactJS, Redux, NestJS, NodeJS, Java 8/11/17, Spring boot, HTML, CSS and TypeScript.',
    'Fast learning, self-organization, responsibility and flexibility help me resolve issues and find the best solutions.',
    'I Have strong skills in: '
  ],
  skills: [
    'JavaScript (ES6-ES8), Java (8/11/17), Typescript',
    'React.js/Next.js, Redux, Redux-Saga, Redux-Thunk, Redux-Observable, React-Logic, Hooks',
    'Material UI, Ant Design, PrimeReact',
    'Firebase',
    'HTML5/CSS3/SASS',
    'Bootstrap / JQuery / responsive',
    'Git / Yarn / npm',
    'API integration',
    'Collaboration Tools - JIRA, Github, Trello',
    'Methodology - Agile, Scrum',
    'IDE - Visual Studio, Jetbrains Webstorm, Sublime, Eclipse',
    'Deployment tools: Heroku, Jenkins, Vercel, ...'
  ]
};

export const educations = [
  {
    year: '2012',
    role: 'Physical science',
    description: 'Bachelor degree in Physical science and chemistry',
    school: 'A Zaitoun school'
  },
  {
    year: '2017',
    role: 'Fullstack developer',
    description: 'Master’s degree in Computer Science & Engineering',
    school: 'SUPEMIR (Higher School of Computer Science and Network)'
  }
];


export const experiences = [
  {
    company: 'IDEMIA',
    periode: '02/2024 to present',
    duration: '',
    role: 'Senior fullstack developer ReactJS/ Java',
    projectName: 'WLE',
    projectDescription: [
      'IDEMIA is a multinational technology company headquartered in Courbevoie, France. It provides identity-related security services, and sells facial recognition and other biometric identification products and software to private companies and governments.'
    ],
    team: [
      '9 Fullstack developers',
      'Product owner',
      'Scrum master',
      'Web tester',
      'Tech lead'
    ],
    tasks: [
      

    ],
    technicalStack: [
      'ReactJS',
      'Typescript',
      'Hooks',
      'Java 17',
      'Spring boot',
      'Oracle / Postgres',
      'PrimeReact',
      'Css/Sass',
      'Eslint',
      'Prettier',
      'Github',
      'Gira'
    ]
  },
  {
    company: 'FASST',
    periode: '03/2023 to 11/2024',
    duration: '1 year',
    role: 'Senior fullstack developer ReactJS/ NodeJs',
    projectName: 'MALAKOFF humanis',
    projectDescription: [
      'FASST is an insurtech company based in France'
    ],
    team: [
      '5 Fullstack developers',
      'Product owner',
      'scrum master',
      'web tester',
      'Tech lead'
    ],
    tasks: [
      'Using HTML, JavaScript and CSS to bring concepts to life',
      'Participate into the brainstorming meeting',
      'Code review',
      'Develop & Improve existing components of design-system library (UI library)',
      'Fixing bugs and testing for usability',
      'Deploy the apps in differents environments',
    ],
    technicalStack: [
      'ReactJS',
      'Typescript',
      'Hooks',
      'GraphQL',
      'MongoDB',
      'Micro services',
      'Docker',
      'Ramda',
      'Css/Sass',
      'Eslint',
      'Prettier',
      'Github',
      'Gira'
    ]
  },
  {
    company: 'MAIF',
    periode: '03/2022 to 02/2023',
    duration: '1 year',
    role: 'Senior developer React JS',
    projectName: 'maif.fr (Accessibility)',
    projectDescription: [
      'MAIF is an insurance company based in France'
    ],
    team: [
      'Frontend developer',
      '3 web integrator',
      'product owner',
      'scrum master',
      'web tester',
      'lead devs'
    ],
    tasks: [
      'Improving page structure and using html semantic.',
      'Making the existing library components accessible.',
      'Auto testing of accessibility with a11y and axe-core engine.',
      'Developing a library to check the accessibility of website pages.',
      'Fixing bugs and testing for usability.',
      'Deploy the apps in differents environments.',

    ],
    technicalStack: [
      'ReactJS',
      'Hooks',
      'Typescript',
      'A11y',
      'Axe-core',
      'Eslint',
      'Prettier',
      'Styleguidist',
      'Github',
      'Gira',
      'Genkins',
      'Sonarqube'
    ]
  },
  {
    company: 'Société Générale',
    periode: '05/2020 to 06/2021',
    duration: '1 year & 3 months',
    role: 'Senior developer React JS Node JS',
    projectName: 'E-control',
    projectDescription: [
      'Control & management of risks: the aim of the project is to control the data coming from all the entities of the Société Générale group'
    ],
    team: [
      '7 Full-stack developer', '4 POs', '3 BAs', 'Tech lead', 'Project manager', 'Scrum master'
    ],
    tasks: [
      'Developing and maintaining the user interface',
      'Maintain and improve website',
      'Optimizing the user experience',
      'Using HTML, JavaScript and CSS to bring concepts to life',
      'Writing unit test',
      'Content management system development, deployment, and maintenance',
      'Security settings and hack prevents',
      'API integration',
      'Managing software workflow',
      'Fixing bugs and testing for usability'
    ],
    technicalStack: [
      'ReactJS', 'Redux', 'NodeJS', 'AWS S3', 'Material UI', 'Styled-components', 'Jenkins', 'OpenShift', 'Docker', 'Postgresql', 'SqlServer', 'loopback', 'Jest', 'Webback'
    ]
  },
  {
    company: 'Energisme',
    periode: '05/2019 to 05/2020',
    duration: '1 year',
    role: 'Developer React JS Node JS',
    projectName: 'Codec Management',
    projectDescription: [
      'Codec management is an IoT (Internet Of Things) project to encrypt millions of data frames collected every day from different clients'
    ],
    team: [
      'Engineer System', '2 developers frontend', 'Product owner', 'Project director', 'Scrum master'
    ],
    tasks: [
      'Using HTML, JavaScript and CSS to bring concepts to life',
      'Developing and maintaining the user interface',
      'Developing an authentication service with JWT (JSON Web Token)',
      'Writing unit test',
      'Improve the performance of data decryption modules',
      'Managing software workflow',
      'Following SEO best practices',
      'Fixing bugs and testing for usability'
    ],
    technicalStack: [
      'ReactJS', 'Webback', 'Redux', 'NodeJS', 'Kafka JS', 'Sqlite3', 'Oracle 12C', 'Jest', 'Mochajs', 'Chaijs', 'Jenkins'
    ]
  },
  {
    company: 'Adria B&T',
    periode: '07/2017 to 03/2019',
    duration: '1 year et 9 months',
    role: 'Full stack developer ReactJS/Java',
    projectName: ' Bank digital',
    projectDescription: [
      'It\'s a digital bank solution allows users to manage their bank accounts'
    ],
    team: [
      '2 frontend developers', '1 backend developer', 'Team lead', 'Product owner', 'project manager'
    ],
    tasks: [
      'Implementing design on mobile websites',
      'Maintain and improve website',
      'Optimize applications for maximum speed',
      'Design mobile-based features',
      'Collaborate with back-end developers and web designers to improve usability',
      'Write functional requirement documents and guides',
      'Help back-end developers with coding and troubleshooting'
    ],
    technicalStack: [
      'ReactJs', 'Redux', 'WebPack', 'Java', 'Spring (MVC4, Security 4, Data2)', 'Maven', 'Hibernate 5', 'Oracle 12C'
    ]
  },
  {
    company: 'Artelia Group',
    periode: '10/2016 to 05/2017',
    duration: '8 months',
    role: 'Developer frontend Angular2',
    projectName: ' Training management',
    projectDescription: [
      'It\'s a platform to manage formations and training for the company\'s stuff'
    ],
    team: [],
    tasks: [
      'Bringing a concept to life with HTML, CSS, and JavaScript',
      'Implementing design for mobile sites',
      'Maintaining software workflow management',
      'Looking at SEO best practices',
      'Testing the site for usability and fixing any bugs'
    ],
    technicalStack: [
      'Angular4', 'Spring (MVC4, Security 4, Data2)', 'Maven', 'Hibernate 5', 'MySQL'
    ]
  },
  {
    company: 'Foxtrade',
    periode: '03/2015 to 09/2015',
    duration: '6 months',
    role: 'Full-stack developer',
    projectName: 'Foxtrade',
    projectDescription: [
      'Helping for the development of an online store to sell accessories for men, women, children, home ...'
    ],
    team: [],
    tasks: [
      'Database creation, integration, and management',
      'API integration',
      'Testing the site for usability and fixing any bugs',
      'Reporting—generating analytics and statistics',
      'Stay up-to-date on emerging technologies',
      'Using HTML, JavaScript and CSS to bring concepts to life',

    ],
    technicalStack: [
      'JavaEE (Spring boot)', 'Hibernate', 'Maven', 'Oracle 11g', 'JavaScript', 'UML', 'Bootstrap', 'Scrum'
    ]
  },
  {
    company: 'Air Maroc',
    periode: '07/2014 to 09/2014',
    duration: '3 months',
    role: 'Web developer',
    projectName: '',
    projectDescription: [
      'Participating in the development of an e-commerce website'
    ],
    team: [],
    tasks: [
      'Fixing bugs and testing for usability',
      'Create quality mockups and prototypes',
      'Fixing bugs and testing for usability',

    ],
    technicalStack: [
      'JavaEE (Spring boot)', 'Hibernate', 'Maven', 'Oracle 11g', 'JavaScript', 'UML', 'Bootstrap', 'Scrum'
    ]
  }
]

//Skills

export const programmingLanguages = [
  {
    name: 'Javascript',
    progress: '80'
  },
  {
    name: 'Java',
    progress: '50'
  },
  {
    name: 'PHP',
    progress: '50'
  },
  {
    name: 'Python',
    progress: '30'
  },
  {
    name: 'Ruby',
    progress: '30'
  },
];

export const frameworks = [
  {
    name: 'ReactJS',
    progress: '85'
  },
  {
    name: 'NextJS',
    progress: '80'
  },
  {
    name: 'NodeJS',
    progress: '75'
  },
  {
    name: 'ExpressJS',
    progress: '80'
  },
  {
    name: 'AngularJS',
    progress: '60'
  },
  {
    name: 'Angular',
    progress: '60'
  },
  {
    name: 'Bootstrap',
    progress: '90'
  },
  {
    name: 'Mterial UI',
    progress: '90'
  },
  {
    name: 'Styled-Component',
    progress: '90'
  },
  {
    name: 'Tailwindcss',
    progress: '80'
  },
  {
    name: 'Loopbak 3 & 4',
    progress: '75'
  },
  {
    name: 'Sequelize',
    progress: '70'
  },
  {
    name: 'Prisma',
    progress: '75'
  },
  {
    name: 'Ruby on rails ',
    progress: '40'
  },
  {
    name: 'Spring',
    progress: '50'
  },
  {
    name: 'Hibernate',
    progress: '70'
  }
];

export const databases = [
  {
    name: 'Postgresql',
    progress: '80'
  },
  {
    name: 'Mysql',
    progress: '80'
  },
  {
    name: 'Oracle',
    progress: '70'
  },
  {
    name: 'MariaDB',
    progress: '80'
  },
  {
    name: 'Mongodb',
    progress: '50'
  }
];

export const IDEs = [
  {
    name: 'VSCode',
    progress: '90'
  },
  {
    name: 'Intellij idea ',
    progress: '90'
  },
  {
    name: 'Eclipse',
    progress: '80'
  },
  {
    name: 'Atom',
    progress: '80'
  },
  {
    name: 'Dbeaver',
    progress: '90'
  },
  {
    name: 'SQLServer',
    progress: '70'
  },
  {
    name: 'Oracle Developer Studio',
    progress: '75'
  }
];

export const unitTest = [
  {
    name: 'Jest',
    progress: '60'
  },{
    name: 'React testing library',
    progress: '70'
  },
  {
    name: 'Cypress',
    progress: '60'
  },
  {
    name: 'ChaiJS',
    progress: '70'
  },
  {
    name: 'MochaJS',
    progress: '70'
  },
];

export const clounds = [
  {
    name: 'Firebase',
    progress: '50'
  },
  {
    name: 'AWS S3',
    progress: '80'
  },
  {
    name: 'Serverless',
    progress: '50'
  },
];


// {
//   company: 'Personal project',
//   periode: '08/2021 to 12/2021',
//   duration: '4 months',
//   role: 'Senior developer React JS NextJS',
//   projectName: 'Online money transfer',
//   projectDescription: [
//     'Online money transfer is a platform that allows immigrants to send money to their homeland to support family, friends, ...',
//     'I stopped working on the project because of the complexity of the rules and the requirements to start the project by the authority.'
//   ],
//   team: [
//     '1 Full-stack developer'
//   ],
//   tasks: [
//     'Developing all user interfaces',
//     'Develop and secure the authentication system',
//     'Developing REST API',
//     'Optimizing the user experience',
//     'Writing unit test',
//     'Security settings and hack prevents',
//     'Managing software workflow',
//   ],
//   technicalStack: [
//     'ReactJS', 'NextJS', 'Styled-components', 'Postgresql', 'Webback', 'Eslint', 'Unit test'
//   ]
// },