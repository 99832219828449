import React from 'react';
import { BsCheck2Circle } from 'react-icons/bs';
import { themeBlue } from '../constants/colors';
import { aboutMe } from '../data/Data';
import { Link } from 'react-router-dom';

const AboutMe = () => {
  return (
    <div className='aboutme'>
      {/**About me picture*/}
      <div className='bg-image' />
      {/* <img src={Image} alt='' /> */}
      {/**About me message*/}
      <div className='description'>
        <h1>Who am I?</h1>
        <div>
          {
            aboutMe.textInfo.map((text, index) => {
              return(
                <p key={index}>{text}</p>
              )
            })
          }
          {
            aboutMe.skills.map((skill, index) => {
              return(
                <div key={index} className='intro-skills'>
                  <span><BsCheck2Circle size={20} color={themeBlue} /></span>
                  <p>{skill}</p>
                </div>
              )
            })
          }
        </div>
        <div className='download-button'>
          <button>
            <Link to="/files/R_LAMGHARI_CV.pdf" target="_blank" download>Download my resume</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;