import React from 'react';
import { MdErrorOutline } from 'react-icons/md';

const Error = () => {
  return (
    <div className='error-page'>
      <div className='content'>
        <MdErrorOutline size={100} />
        <h1>There's nothing here</h1>
      </div>
    </div>
  );
};

export default Error;