import { Route, Routes, useLocation } from 'react-router-dom';
import AppLayout from './Layout/AppLayout';
import AboutMe from './pages/AboutMe';
import Contact from './pages/Contact';
import Error from './pages/Error';
import Resume from './pages/Experiences';
import Skills from './pages/Skills';
import { useEffect } from 'react';
import './style/index.scss';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <AppLayout>
      <Routes>
        <Route path='/' element={<AboutMe />} />
        <Route path='/aboutme' element={<AboutMe />} />
        <Route path='/experiences' element={<Resume />} />
        <Route path='/skills' element={<Skills />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </AppLayout>
  );
}

export default App;
