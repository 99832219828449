import React from 'react';
import { HiOutlineLightBulb, HiUserGroup } from 'react-icons/hi';
import { MdDescription } from 'react-icons/md';
import { BsListTask, BsTools } from 'react-icons/bs';
import { themeBlue } from '../../constants/colors';

const ExperienceCard = ({
  experience,
  index
}: any) => {
  return (
    <div key={index} className="entry">
      <div className="title">
        <h3>{experience.periode}</h3>
        <p>{experience.company}</p>
      </div>
      <div className="body">
        <h3>{experience.role}</h3>
        <div>
          {/**Project name */}
          <div>
            <span><HiOutlineLightBulb size={20} color={themeBlue} />&nbsp;Project name:</span>
            <p>{experience.projectName}</p>
          </div>
          {/**Project description */}
          <div>
            <span><MdDescription color={themeBlue} />&nbsp;Description:</span>
            <div>
              {
                experience.projectDescription.map((desc: any, index: number) => {
                  return(
                    <p key={index}>{desc}</p>
                  )
                })
              }
            </div>
          </div>
         {/**Project team members */}
         <div>
            <span><HiUserGroup color={themeBlue} />&nbsp;Team:</span>
            <ul>
              {
                experience.team.map((member: any, index: number) => {
                  return (
                    <li key={index}>{member}</li>
                  )
                })
              }
            </ul>
          </div>

          {/**Project tasks */}
          <div>
            <span><BsListTask color={themeBlue} />&nbsp;Tasks:</span>
            <ul>
              {
                experience.tasks.map((task: any, index: number) => {
                  return(
                    <li key={index}>{task}</li>
                  )
                })
              }
            </ul>
          </div>
         
          {/**Project technical stack */}
          <div>
            <span><BsTools color={themeBlue} />&nbsp;Technical stack:</span>
            <ul>
              {
                experience.technicalStack.map((stack: any, index: number) => {
                  return(
                    <li key={index}>{stack}</li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceCard;