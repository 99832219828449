import React from 'react';

const SkillProgress = ({
  language,
  key
}: any) => {
  return (
    <div className='skill-progress'>
      <div key={key} className="skill-score">
        <span className="name">{language.name}</span>
        <span className="score">{language.progress || 0}%</span>
      </div>
      <div className="progress-container">
        <div className="progress-bar" style={{width: `${language.progress || 0}%`}}></div>
      </div>
    </div>
  );
};

export default SkillProgress;