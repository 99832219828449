import React, { useRef, useState } from 'react';
import Toastr from 'toastr';
import { MdEmail, MdLocationOn, MdPhone } from 'react-icons/md';
import { themeBlue } from '../constants/colors';
import functions from '../firebase/config';
import ExperienceBanner from '../components/experience-banner/ExperienceBanner';

Toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  showDuration: 300,
  hideDuration: 1000,
  timeOut: 5000,
  extendedTimeOut: 1000,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut"
};

const Contact = () => {
  const [values, setValues] = useState({});
  const formRef: any = useRef();
  const handleChange = (event: any) => {
    const {name, value} = event.target;
    setValues({
      ...values,
      [name]: value
    })
  }
  const handleSubmit = (event: any) => {
    event.preventDefault();
    console.log(values);
    var addMessage = functions.httpsCallable('sendEmail');
    addMessage({ ...values })
      .then((res: any) => {
        event.target.reset();
        Toastr.success('Thank you for reaching out, I will get back to you as soon as possible', 'Congratulations!');
      })
      .catch((err: any) => {
        Toastr.error('Something went wrong, please try again later', 'Oooops!');
      });
  }
  return (
    <div className='contact'>
      <ExperienceBanner pageName='Contact' />
      <div className='contact-block'>
        <div className='contact-form'>
          <form ref={formRef} id='contact-form' onSubmit={event => handleSubmit(event)}>
            <div className="contact-fields">
              <div className="input-group">
                <label htmlFor="first-name">First name</label>
                <input
                  type="text"
                  id="first-name"
                  name="firstname"
                  placeholder='Firstname'
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="input-group">
                <label htmlFor="last-name">Last name</label>
                <input
                  type="text"
                  id="last-name"
                  name="lastname"
                  placeholder='Lastname'
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="input-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder='Email'
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="input-group">
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  placeholder='Subject'
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="input-group">
                <label htmlFor="subject">
                  <div className="flex align-items">
                    Message
                    <span className="ml-auto opacity-75">Max. 500 characters</span>
                  </div>
                </label>
                <textarea
                  maxLength={500}
                  rows={4}
                  id="message"
                  name="message"
                  placeholder='Message'
                  required
                  onChange={handleChange}
                />
              </div>
              <div className='button-block'>
                <button>
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className='contact-detail'>
          <div className='contact-row'>
            <div className='contact-card'>
              <MdLocationOn size={30} color={themeBlue} />
              <h3>Casablanca Morocco</h3>
            </div>
            <div className='contact-card'>
              <MdPhone size={30} color={themeBlue} />
              <h3>06 00 00 00 00</h3>
            </div>
            <div className='contact-card'>
              <MdEmail size={30} color={themeBlue} />
              <h3>contact@ridouanlamghari.com</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;