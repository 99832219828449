import React from 'react';
import EducationCard from '../components/cards/EducationCard';
import ExperienceCard from '../components/cards/ExperienceCard';
import { educations, experiences } from '../data/Data';
import ExperienceBanner from '../components/experience-banner/ExperienceBanner';

const Experiences = () => {
  return (
    <div className='experience'>
      <ExperienceBanner pageName='Experiences' />
      <div className='flex'>
        <div className='w-11/12'>
          {/**Educations */}
          <h2>Educations/Certificates</h2>
          <div className="timeline">
            {
              educations.map((education, index) => {
                return(
                  <EducationCard
                    education={education}
                    key={index}
                  />
                )
              })
            }
          </div>
          {/**Experiences */}
          <h2>Experiences</h2>
          <div className="timeline">
            {
              experiences.map((experience, index) => {
                return(
                  <ExperienceCard
                    experience={experience}
                    key={index}
                  />
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experiences;