import React from 'react';

const EducationCard = ({
  education,
  index
}: any) => {
  return (
    <div key={index} className="entry">
      <div className="title">
        <h3>{education.year}</h3>
        <p>{education.school}</p>
      </div>
      <div className="body">
        <h3>{education.role}</h3>
        <p>{education.description}</p>
      </div>
    </div>
  );
};

export default EducationCard;